import { AButton } from '@allvit/allvit-toolkit';
import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from '@remix-run/react';
import { Dispatch, SetStateAction, useState } from 'react';

import { imageUrls } from '~/assets';
import WarningTriangleIcon from '~/components/atoms/Icon/icons/WarningTriangle';
import { UserTypes } from '~/types/app/user';
import { useCurrentUser } from '~/utils/user';

export const AllvitPlussBannerDashboard = ({
    userIsSubscriber,
    setShowSubModal,
}: {
    userIsSubscriber: boolean;
    setShowSubModal?: Dispatch<SetStateAction<boolean>>;
}) => {
    const { user, subscriptionExpiresWithinTwoWeeks } = useCurrentUser();
    const location = useLocation();
    const theme = useTheme();

    const [organizationName] = useState(() => {
        return Array.isArray(user.organisation.seats) && user.organisation.seats.length > 0
            ? user.organisation.seats[0].organization.name
            : '';
    });

    const [orgSubscriptionActive] = useState<boolean>(() => {
        return (
            Array.isArray(user.organisation.seats) &&
            user.organisation.seats.length > 0 &&
            user.organisation.seats.some((el) => el.active)
        );
    });

    const [bannerContent] = useState<JSX.Element>(() =>
        getBannerContent(
            user,
            userIsSubscriber,
            organizationName,
            orgSubscriptionActive,
            theme,
            location,
            subscriptionExpiresWithinTwoWeeks,
            setShowSubModal,
        ),
    );

    return (
        <Stack
            sx={{
                py: { xs: 3, sm: 6 },
                pr: { xs: 6, sm: '180px' },
                pl: { xs: 3, md: 8 },
                alignItems: { xs: 'center', md: 'center' },
                maxWidth: '600px',
                flexDirection: 'row',
                backgroundColor:
                    theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.dark,
                backgroundImage: { xs: 'none', sm: `url(${imageUrls.extractedSmartStudent})` },
                backgroundPosition: 'bottom right',
                backgroundSize: '130px auto',
                backgroundRepeat: 'no-repeat',
                borderRadius: theme.radius.allLarge,
            }}
        >
            <Typography
                variant="p1"
                sx={{
                    display: 'block',
                    color: theme.palette.primary.contrastText,
                    textAlign: { xs: 'center', sm: 'left' },
                }}
            >
                {bannerContent}
            </Typography>
        </Stack>
    );
};

function getBannerContent(
    user: UserTypes,
    userIsSubscriber: boolean,
    organizationName: string,
    orgSubscriptionActive: boolean,
    theme: Theme,
    location: ReturnType<typeof useLocation>,
    subscriptionExpiresSoon: boolean,
    setShowSubModal?: Dispatch<SetStateAction<boolean>>,
) {
    if (user.organisation.isOrganisationUser) {
        if (orgSubscriptionActive) {
            return (
                <>
                    Du er en del av <b>{organizationName}</b> og har derfor tilgang til Allvit+ for bedrift.&nbsp;
                    <AButton
                        component={Link}
                        to="/sok/boker?allvitPluss=1&gratis=1"
                        state={{ from: location.pathname }}
                        overrideColor={theme.palette.primary.contrastText}
                        variant="link"
                    >
                        Søk etter bøker i Allvit+
                    </AButton>
                </>
            );
        } else {
            return (
                <>
                    Du vil få abonnementet Allvit+ for bedrift så snart det er en ledig plass.{' '}
                    <AButton
                        variant="link"
                        href="https://support.allvit.no/knowledge/antall-plasser-i-bedriftsabonnement"
                        target="_blank"
                        overrideColor={theme.palette.primary.contrastText}
                    >
                        Les mer
                    </AButton>
                </>
            );
        }
    } else if (userIsSubscriber && !subscriptionExpiresSoon) {
        return (
            <>
                Du har Allvit+ og har tilgang til omtrent 2000 bøker!&nbsp;
                <AButton
                    component={Link}
                    to="/sok/boker?allvitPluss=1&gratis=1"
                    state={{ from: location.pathname }}
                    overrideColor={theme.palette.primary.contrastText}
                    variant="link"
                >
                    Søk etter bøker i Allvit+
                </AButton>
            </>
        );
    } else if (userIsSubscriber && subscriptionExpiresSoon) {
        return (
            <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <WarningTriangleIcon
                    sx={{
                        color: theme.palette.warning.main,
                        fontSize: '2.5rem',
                        mr: 4,
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    }}
                />
                <Box>
                    Abonnementet ditt utløper snart! <br />
                    <AButton
                        overrideColor={theme.palette.primary.contrastText}
                        variant="link"
                        onClick={() => {
                            if (setShowSubModal) {
                                setShowSubModal(true);
                            }
                        }}
                    >
                        Forny abonnementet ditt her.
                    </AButton>
                </Box>
            </Stack>
        );
    } else {
        return (
            <>
                Med Allvit+ får du tilgang til omtrent 2000 bøker!{' '}
                <AButton
                    component={Link}
                    to="/start-allvit-pluss"
                    overrideColor={theme.palette.primary.contrastText}
                    variant="link"
                >
                    Start Allvit+ nå!
                </AButton>
            </>
        );
    }
}
